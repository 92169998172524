<template>
  <div class="row g-0">
    <div class="col-lg-3">
      <h2 class="section-title" @click="$router.push('/golfcourse/courselist')">
        {{ $t("title.main.golfcourse")
        }}<i class="material-icons">navigate_next</i>
      </h2>
      <span class="section-describe">{{
        $t("text.main.golfcourse_describe")
      }}</span>
      <div class="slide-btn">
        <a class="prev cc_prev"
          ><i class="material-icons-round">arrow_back_ios</i></a
        >
        <a class="next cc_next"
          ><i class="material-icons-round">arrow_forward_ios</i></a
        >
      </div>
    </div>
    <swiper class="swiper col-lg-9" ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="(item, index) in courselist" :key="index">
        <router-link
          :to="{ name: 'CourseView', params: { id: item.courseno } }"
          class="position-relative view"
        >
          <a class="position-relative view">
            <img :src="(item.path + '/' + item.image) | get_img" />
            <div class="mask"></div>
            <span class="icon-view"></span>
          </a>
          <div>
            <h6 class="mb-3">[{{ item.area }}] {{ item.coursename }}</h6>
            <ul>
              <li>
                <span>{{ $t("title.golfcourse.golfcourse_difficulty") }}</span>
                <span v-for="index in 5" :key="index"
                  ><i
                    class="material-icons-outlined"
                    :class="
                      item.field >= index ? 'text-yellow' : 'text-lightgray'
                    "
                    >star</i
                  ></span
                >
              </li>
              <li>
                <span>{{ $t("title.golfcourse.green_difficulty") }}</span>
                <span v-for="index in 5" :key="index"
                  ><i
                    class="material-icons"
                    :class="
                      item.green >= index ? 'text-green' : 'text-lightgray'
                    "
                    >flag</i
                  ></span
                >
              </li>
            </ul>
          </div>
        </router-link>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "Course",
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    cc_img(index) {
      if (this.courselist[index].image !== null)
        return this.courselist[index].path + "/" + this.courselist[index].image;
      else return "/img/noccimage.jpg";
    },
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 3000,
        },
        spaceBetween: 24,
        navigation: {
          nextEl: ".cc_next",
          prevEl: ".cc_prev",
        },
        slidesPerView: 1,

        breakpoints: {
          576: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          },
        },
      },
    };
  },
  //참조하고 있는 값이 변경될 때마다 정의된 계산식에 따라 값을 출력
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  props: ["courselist"],
};
</script>
