<template>
  <div class="row row-cols-1 row-cols-md-2 row-cols-xxl-4 g-5 g-md-4 g-xl-5">
    <template v-for="(item, index) in tournamentlist">
      <div :key="index">
        <div class="inbox">
          <router-link
            :to="{ name: 'ShopCondition', params: { id: item.tournamentno } }"
            class="border"
          >
            <img
              :src="item.tourbinary[0] && item.tourbinary[0].filepath | get_img"
            />
            <div class="text-center">
              <span class="badge bg-black text-capitalize">
                {{ tournament_type(item.tourtype_fk) }}
              </span>
              <h5 class="my-2 text-center">
                {{ item.title }}
              </h5>

              <!-- 서비스 시간 기준 -->
              <p class="text-center text-date fs-14">
                <!-- <i class="material-icons">date_range</i> -->

                {{
                  item.timezone_startdate.timezone_common
                    | dateformat("YYYY.MM.DD")
                }}
                ~
                {{
                  item.timezone_enddate.timezone_common
                    | dateformat("YYYY.MM.DD")
                }}
              </p>
              <!-- <p>
                <i class="material-icons-outlined">explore</i>
                {{ item.address || "Unregistered" }}
              </p> -->
            </div>
          </router-link>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { myMixin } from "@/mixin/myMixin";
export default {
  name: "Tournament",
  props: ["tournamentlist"],
  mixins: [myMixin],
};
</script>
