<template>
  <div
    class="tourn-card row row-cols-1 row-cols-md-2 row-cols-xxl-4 g-5 g-md-4 g-xl-5"
  >
    <template v-for="(item, index) in tournamentlist">
      <div class="col" :key="index">
        <router-link
          :to="{ name: 'Condition', params: { id: item.tournamentno } }"
        >
          <div class="card h-100">
            <div class="card-img-wrapper">
              <img
                :src="
                  item.tourbinary[0] && item.tourbinary[0].filepath | get_img
                "
              />
            </div>
            <div class="card-body">
              <span class="badge rounded-pill">{{
                tournament_type(item.tourtype_fk)
              }}</span>
              <h5 class="card-title">{{ item.title }}</h5>
            </div>
            <div class="card-footer text-truncate text-date">
              <small>
                {{
                  item.timezone_startdate.timezone_common
                    | dateformat("YYYY.MM.DD")
                }}
                ~
                {{
                  item.timezone_enddate.timezone_common
                    | dateformat("YYYY.MM.DD")
                }}
              </small>
            </div>
          </div>
        </router-link>
      </div>
    </template>
  </div>
</template>
<script>
import { myMixin } from "@/mixin/myMixin";
export default {
  name: "Tournament",
  props: ["tournamentlist"],
  mixins: [myMixin],
};
</script>
